const VAT_PERCENTAGE = 25.5

const TODAY_STATS_DEVICE_CONFIG = {
    maxPrice: 1000,
    numberOfHours: 6,
    firstHour: 0,
    lastHour: 23,
    consecutive: false,
    mode: "automatic"
}

const DEFAULT_DEVICE_CONFIG = {
    maxPrice: 25,
    numberOfHours: 6,
    firstHour: 22,
    lastHour: 7,
    consecutive: false,
    mode: "automatic"
}

exports.VAT_PERCENTAGE = VAT_PERCENTAGE;
exports.DEFAULT_DEVICE_CONFIG = DEFAULT_DEVICE_CONFIG;
exports.TODAY_STATS_DEVICE_CONFIG = TODAY_STATS_DEVICE_CONFIG;