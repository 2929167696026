import { React, useContext, useEffect, useState } from "react"
import { ApiContext } from "../../ApiContext"
import "./TodayStats.css"
import { TODAY_STATS_DEVICE_CONFIG, VAT_PERCENTAGE } from "spot-automation-common"
import { calculateSavingsToday } from "../../utils/SavingsCalculation"

function TodayStats() {
    const { ApiCall, todayPrices } = useContext(ApiContext)
    const [activity, setActivity] = useState()
    const [savings, setSavings] = useState()
    const [priceNow, setPriceNow] = useState()

    useEffect(() => {
        if (!todayPrices) {
            return
        }
        else {
            if (!priceNow) {
                const now = new Date()
                let price = 0
                todayPrices.forEach(it => {
                    if (now.getHours() == it.date.getHours()) {
                        price = it.price
                    }
                })
                setPriceNow(price)
            }
        }
        if (!activity) {
            ApiCall("POST", "device/usage", TODAY_STATS_DEVICE_CONFIG).then((res) => {
                const act = res.map(it => ({
                    date: new Date(it.date),
                    price: (it.price * (100 + VAT_PERCENTAGE))
                }))
                act.sort((a, b) => a.date.getTime() - b.date.getTime())
                setActivity(act)
            }).catch((err) => {
                console.error(err)
            })
            return
        }
        if (!savings) {
            setSavings(calculateSavingsToday(todayPrices, activity))
            return
        }
        console.log("Stats today", priceNow, savings)
    }, [todayPrices, activity, savings])
    console.log("savings", savings)
    const shouldShowPriceStats = priceNow && savings && savings.max >= 50
    return <>
        <div className="today-stats">
            <div className={shouldShowPriceStats ? "today-stats-container visible" : "today-stats-container"}>
                <p className="today-stats-title">Sähkön hinta nyt</p>
                <p className="today-stat">{priceNow ? priceNow.toFixed(0) : 0} snt</p>
            </div>
            <div className={shouldShowPriceStats ? "today-stats-container visible" : "today-stats-container"} style={{ textAlign: "end" }}>
                <p className="today-stats-title">Säästö tänään jopa</p>
                <p className="today-stat">{savings ? (savings?.median / 100).toFixed(1) : 0} - {savings ? (savings?.max / 100).toFixed(1) : 0} €</p>
            </div>
        </div>
    </>
}

export default TodayStats